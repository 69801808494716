<template>
  <page-manage-base>
    <template v-slot:title>{{ $route.name }}</template>
    <!-- Table 下書き -->
    <template>
      <div class="d-flex justify-space-between mx-16 mb-2 mt-5">
        <h2 class="textTile">{{ $t('webPageManage.notice.draft') }}</h2>
        <v-btn
          v-if="checkPerUser"
          @click="checkPerUser ? (visible = 4) : ''"
          class="t-btn--prm"
        >
          <span>
            <i class="fas fa-plus"></i>&emsp;
            {{ $t('webPageManage.notice.NotificationCreation') }}
          </span>
        </v-btn>
      </div>
      <v-card class="mx-16">
        <Table
          :attr="{
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id',
          }"
          ref="table"
          :headers="headersDraft"
          :items="webNotificationNotPublishedList"
          :hideFooter="true"
          defaultSortBy="status"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{
              item.createdAt
                .substr(0, 10)
                .split('-')
                .join('/')
            }}
          </template>
          <template v-slot:[`item.image`]="{ item }">
            {{ item.imageUrl ? 'あり' : 'なし' }}
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              :disabled="!checkPerUser"
              class="btn-crm-primary"
              style="border: 0px"
              icon
            >
              <v-icon @click="checkPerUser ? showEditing(1, item) : ''" x-large
                >mdi-pencil-circle</v-icon
              >
            </v-btn>
          </template>
        </Table>
      </v-card>
    </template>
    <!-- end table 下書き -->

    <!-- Table お知らせ -->
    <template>
      <div class="d-flex justify-space-between mx-16 mt-3">
        <h2 class="textTile mt-4">{{ $t('webPageManage.notice.notice') }}</h2>
        <v-checkbox
          v-model="filter.includeHidden"
          :label="this.$t('webPageManage.notice.ShowUnpublishedPosts')"
        ></v-checkbox>
      </div>
      <v-card class="mx-16">
        <Table
          :total="getTotalNotice"
          :attr="{
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id',
            class: 'table-cus-footer',
          }"
          ref="table"
          :itemsPerPage="10"
          :itemsPerPageOptions="[10, 20, 30, 50, 100, 200, 500]"
          :headers="headersNotice"
          :items="webNotificationPublishedList"
          defaultSortBy="showFromDate"
          :sortField="listSortField"
          :filter="filter"
          :funReset="fetchPublishedList"
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{
              item.createdAt
                .substr(0, 10)
                .split('-')
                .join('/')
            }}
          </template>
          <template v-slot:[`item.showFromDate`]="{ item }">
            {{ item.showFromDate.split('-').join('/') }}
          </template>
          <template v-slot:[`item.showToDate`]="{ item }">
            {{ item.showToDate.split('-').join('/') }}
          </template>
          <template v-slot:[`item.important`]="{ item }">
            {{ item.important ? '重要' : '' }}
          </template>
          <template v-slot:[`item.imageUrl`]="{ item }">
            {{ item.imageUrl ? 'あり' : 'なし' }}
          </template>

          <template v-slot:[`item.edit`]="{ item }">
            <v-btn
              v-if="checkPerUser"
              class="btn-crm-primary"
              style="border: 0px"
              icon
            >
              <v-icon
                @click="
                  item.status === '掲載'
                    ? showEditing(1, item)
                    : showEditing(1, item)
                "
                x-large
                >mdi-pencil-circle</v-icon
              >
            </v-btn>
            <v-btn
              v-else
              disabled
              class="btn-crm-primary"
              style="border: 0px"
              icon
            >
              <v-icon x-large>mdi-pencil-circle</v-icon>
            </v-btn>
          </template>
        </Table>
      </v-card>
    </template>
    <!-- End Table お知らせ -->

    <!-- Dialog -->
    <noticeInformationEditing
      v-if="checkPerUser"
      :visible="visible > 0 && visible !== 4"
      @close="(visible = -1), (data = {})"
      :page="visible"
      :data="data"
    >
    </noticeInformationEditing>

    <create-notice
      v-if="checkPerUser"
      :visible="visible === 4"
      @close="visible = -1"
    ></create-notice>
    <!-- Dialog -->
  </page-manage-base>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import PageManageBase from '../PageManageBase.vue';
import noticeInformationEditing from './noticeInformationEditing.vue';
import { checkPermissionUserCurrent } from '@/utils/permissions';
import CreateNotice from './createNotice.vue';
import Table from '@/components/Table/index.vue';

export default {
  name: 'NoticeList',
  components: {
    PageManageBase,
    noticeInformationEditing,
    CreateNotice,
    Table,
  },
  data() {
    return {
      listSortField: [
        {
          colName: 'showFromDate', // === value in headers
          name: 'showFromDate',
          fieldName: 'webNotification',
        },
      ],
      filter: {
        published: true,
        includeHidden: false,
      },
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      // title: '',
      // content: '',
      data: {},
      headersDraft: [
        {
          text: '作成日',
          sortable: true,
          value: 'createdAt',
        },
        {
          text: this.$t('webPageManage.notice.status'),
          sortable: false,
          value: 'status',
        },
        {
          text: this.$t('webPageManage.notice.title'),
          sortable: false,
          value: 'title',
          width: '300px',
        },
        {
          text: this.$t('webPageManage.notice.Contents'),
          sortable: false,
          value: 'body',
          width: '300px',
        },
        {
          text: this.$t('webPageManage.notice.image'),
          sortable: false,
          value: 'image',
        },
        {
          text: this.$t('webPageManage.notice.CreateUser'),
          sortable: false,
          value: 'staff.name',
        },
        {
          text: this.$t('webPageManage.notice.edit'),
          sortable: false,
          value: 'edit',
        },
      ],
      headersNotice: [
        {
          text: this.$t('webPageManage.notice.CreatedDate'),
          sortable: false,
          value: 'createdAt',
          width: '120px',
        },
        {
          text: this.$t('webPageManage.notice.ScheduledToStartPosting'),
          sortable: true,
          value: 'showFromDate',
          width: '140px',
        },
        {
          text: this.$t('webPageManage.notice.ScheduledToEndPosting'),
          sortable: false,
          value: 'showToDate',
          width: '140px',
        },
        {
          text: this.$t('webPageManage.notice.status'),
          sortable: false,
          value: 'status',
          width: '120px',
        },
        {
          text: this.$t('webPageManage.notice.important'),
          sortable: false,
          value: 'important',
          width: '90px',
        },
        {
          text: this.$t('webPageManage.notice.title'),
          sortable: false,
          value: 'title',
          width: '230px',
        },
        {
          text: this.$t('webPageManage.notice.Contents'),
          sortable: false,
          value: 'body',
          width: '280px',
        },
        {
          text: this.$t('webPageManage.notice.image'),
          sortable: false,
          value: 'imageUrl',
          width: '90px',
        },
        {
          text: this.$t('webPageManage.notice.CreateUser'),
          sortable: false,
          value: 'staff.name',
          width: '140px',
        },
        {
          text: this.$t('webPageManage.notice.edit'),
          sortable: false,
          value: 'edit',
          width: '90px',
        },
      ],
    };
  },
  mounted() {
    this.fetchWebNotificationPublishedList({
      published: false,
      includeHidden: true,
    });
    this.$refs.table.reset();
  },
  watch: {
    checkReload() {
      this.fetchWebNotificationPublishedList({
        published: false,
        includeHidden: true,
      });
      this.$refs.table.reset();
    },
    filter: {
      deep: true,
      handler() {
        this.$refs.table.reset();
      }
    }
  },
  computed: {
    ...mapGetters([
      'getTotalNotice',
      'webNotificationNotPublishedList',
      'webNotificationPublishedList',
      'checkReload',
    ]),
  },
  methods: {
    ...mapActions([
      'fetchWebNotificationList',
      'fetchWebNotificationPublishedList',
    ]),
    ...mapMutations([
      'setIDNotice',
      'setShowFromDateNotice',
      'setShowToDateNotice',
    ]),

    async fetchPublishedList(variables) {
      if (variables) {
        console.log(5555, variables);
        await this.fetchWebNotificationList({
          published: variables.filter.published,
          includeHidden: variables.filter.includeHidden,
          pagination: variables.pagination,
          orderBy: variables.orderBy,
        });
      }
    },

    showEditing(page, item) {
      if (page === 1) {
        if (item.publishedAt !== null && item.hiddenAt !== null) {
          this.visible = 3;
        } else if (item.publishedAt !== null && item.hiddenAt === null) {
          this.visible = 2;
        } else {
          this.visible = 1;
        }
        this.data = item;
        this.setIDNotice(item.id);
        this.setShowFromDateNotice(item.showFromDate);
        this.setShowToDateNotice(item.showToDate);
      } else {
        this.visible = 4;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
div.divOverflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.notice {
  max-width: 1258px !important;
  margin-top: 15px;
  margin-left: 42px;
  .addNew {
    color: white;
    height: 28px !important;
    min-width: 40px;
  }
  .card-title {
    label {
      font-size: 13px;
    }
  }
  .custom-table {
    .status {
      height: 27px;
      width: 63px;
      border-radius: 6px !important;
    }
    button,
    a {
      height: 40px !important;
      width: 80px;
      font-size: 15px;
      color: white;
    }
  }
}
.v-data-table::v-deep {
  th,
  .v-data-footer,
  .v-select__selection {
    font-size: 14px !important;
  }

  td {
    white-space: nowrap;
    max-width: 300px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
  }
}
.textTile {
  color: #0b6786;
  font-size: 20px;
  font-weight: bold;
}
::v-deep {
  .table-cus-footer {
    .v-data-footer__pagination {
      display: none;
    }
  }
  .v-data-footer__select {
    font-size: 13px !important;
    font-weight: 500;
    color: #000;
    .v-select__selection--comma {
      font-weight: 500;
      color: #000;
    }
  }
  .v-input--checkbox {
    .v-label {
      color: #232222 !important;
      font-size: 16px !important;
      font-weight: 400 !important;
    }
  }
}
.v-data-table::v-deep {
  th,
  .v-data-footer,
  .v-select__selection {
    font-size: 13px !important;
  }
  th {
    color: #757575 !important;
    font-size: 14px !important;
  }
  td {
    color: #232222 !important;
    font-size: 14px !important;
  }
  .v-data-footer__pagination {
    display: none;
  }
  .v-icon__svg {
    color: #333 !important;
  }
}
</style>
