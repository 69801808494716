<template>
  <div>
    <v-dialog @click:outside="closeDialog" v-model="dialog">
      <v-form ref="form">
        <h1>新規お知らせ作成</h1>
        <!-- Content -->
        <div>
          <!-- Content -->
          <div class="mt-5">
            <h4>{{ $t('webPageManage.noticeDetail.title') }}</h4>
            <v-text-field
              outlined
              style="width: 1000px"
              class="mt-1 text-newnotice"
              v-model="title"
              :rules="[$rules.required, $rules.checkLenghInput(255)]"
            ></v-text-field>
          </div>
          <div>
            <h4>{{ $t('webPageManage.noticeDetail.text') }}</h4>
            <v-textarea
              auto-grow
              outlined
              rows="15"
              :rules="[$rules.required, $rules.checkTextAreaLength()]"
              row-height="15"
              class="mt-1"
              v-model="body"
            ></v-textarea>
          </div>
          <!-- End content -->

          <!-- Display img -->
          <div>
            <v-file-input
              style="width: 700px"
              :label="srcImgPre ? srcImgPre : ''"
              filled
              @change="showImagePreview"
              prepend-icon="mdi-camera"
              :messages="[$messages.fileLimit({type: '画像 (JPG、GIF、PND、TIFF、PSD等)'})]"
            ></v-file-input>
          </div>
          <v-card
            class="pl-3"
            height="150px"
            width="250px"
            outlined
            :label="srcImgPre ? srcImgPre : ''"
            :img="srcImgPre"
          >
            <p class="px-16 py-16" :class="{ displayNone: display }">
              {{ $t('webPageManage.noticeDetail.ImagePreview') }}
            </p>
          </v-card>
          <!-- End display img -->

          <!-- Date picker -->
          <div class="d-flex align-center mt-5 mb-10">
            <DatePickerSelectFromTo 
              :dates="listDate"
              :dateFrom="listDate[0].date"
              :dateTo="listDate[1].date"
              @change="updateListDate"
              :attrTagInput="{
                dense: true,
                'hide-details': true,
                label:'掲載日',
                className: 'color-calender',
                type: 'text-field',
                hasBtnResetDatePicker: false,
                outlined: true
              }"
              :icon="true"
              :rules="[$rules.required]"
              showTilde
              @reset="updateListDate"
            ></DatePickerSelectFromTo>
           
          </div>
          <!-- End date picker -->
        </div>

        <!-- End content -->
        <div>
          <div class="d-flex justify-end">
            <div>
              <v-btn large class="t-btn--prm mx-2" @click="save(0)">
                {{ $t('webPageManage.noticeInformationEditing.SaveAsDraft') }}
              </v-btn>
              <v-btn large class="t-btn--prm mx-2" @click="save(1)">
                {{
                  $t(
                    'webPageManage.noticeInformationEditing.PostedAsImportantNotice',
                  )
                }}
              </v-btn>
              <v-btn large class="t-btn--prm mx-2" @click="save(2)">
                {{ $t('webPageManage.noticeInformationEditing.Post') }}
              </v-btn>
            </div>
          </div>
        </div>
        <!-- end button -->
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import gql from 'graphql-tag';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import axios from 'axios';
import DatePickerSelectFromTo from '@/components/Input/datePicker/DatePickerSelectFromTo.vue';

export default {
  name: 'createNotice',
  data() {
    return {
      title: '',
      body: '',
      toDate: null,
      fromDate: null,
      srcImgPre: null,
      display: false,
      menuDate: [],
      listDate: [
        // { id: 1, date: new Date().toISOString().substr(0, 10) },
        // { id: 2, date: new Date().toISOString().substr(0, 10) },
        { id: 1, date: '', attrs: { label: '掲載開始' } },
        { id: 2, date: '', attrs: { label: '掲載終了' } },
      ],
    };
  },
  components : {
    DatePickerSelectFromTo
  },
  props: {
    visible: Boolean,
    page: Number,
    data: Object,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
  },
  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'updateWebNotification',
      'createWebNotification',
      'deleteWebNotification',
      'hiddenWebNotification',
      'fetchWebNotificationList',
    ]),
    ...mapMutations([
      'setCopyErrorText',
      'setTitleNotice',
      'setImportantNotice',
      'setPublishedNotice',
      'setAlertSuccess',
      'setTitleNotice',
      'setBodyNotice',
      'setImageUrlNotice',
      'setShowFromDateNotice',
      'setShowToDateNotice',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),

    showImagePreview(event) {
      if (event != null) {
        const theReader = new FileReader();
        theReader.onloadend = async () => {
          this.srcImgPre = await theReader.result;
        };
        theReader.readAsDataURL(event);
        this.img = event.name;
        this.display = true;
        this.getFile(event);
      } else {
        this.srcImgPre = null;
        this.display = false;
      }
    },

    getFile(e) {
      if (e) {
        this.file = e;
        this.getInfoFile(e.type, e.size, e.name);
      }
    },

    getInfoFile(type, length, name) {
      const query = `
            mutation (
              $contentType: String!
              $contentLength: Int!
              $type: String!
              $fileName: String
            ) {
              generateUploadUrl(
                type: $type
                contentType: $contentType
                contentLength: $contentLength
                fileName: $fileName
              ) {
                uploadUrl
                fileUrl
              }
            }
          `
      const variables = {
            type: 'webNotification/images',
            contentType: type,
            contentLength: length,
            fileName: name,
          }
      this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then((data) => {
          this.uploadUrl = data.data.generateUploadUrl.uploadUrl;
          this.fileUrl = data.data.generateUploadUrl.fileUrl;
          axios.put(this.uploadUrl, this.file, {
            headers: {
              'Content-Type': this.file.type,
            },
          });
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },

    save(x) {
      if (this.$refs.form.validate()) {
        this.setTitleNotice(this.title);
        this.setBodyNotice(this.body);
        this.setImageUrlNotice(this.fileUrl);
        this.setShowFromDateNotice(this.listDate[0].date);
        this.setShowToDateNotice(this.listDate[1].date);
        switch (x) {
          case 0:
            this.setImportantNotice(false);
            this.setPublishedNotice(false);
            break;
          case 1:
            this.setImportantNotice(true);
            this.setPublishedNotice(true);
            break;
          default:
            this.setImportantNotice(false);
            this.setPublishedNotice(true);
        }
        this.createWebNotification();
        this.closeDialog();
      }
    },

    closeDialog() {
      this.$refs.form.reset();
      this.$emit('close');
    },

    updateListDate(item){
      if (item.id === 1) {
        this.listDate[0].date = item.date
      } else {
        this.listDate[1].date = item.date
      }
    }
  },
  
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.boder_padding {
  padding: 10px 0 10px 20px;
}
.boder_gray_padding {
  border-bottom: 1px solid gray;
  padding: 10px 0 10px 20px;
}
.textGray {
  color: gray;
}
.displayNone {
  display: none;
}
h1{
  color: #000000;
  font-size: 22px;
  font-weight: bold;
}
h4{
  font-size: 16px;
  font-weight: bold;
  color: #757575 !important;
}
::v-deep {
  .v-dialog {
    width: 1300px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
  .text-newnotice{
    .v-text-field__slot{
      input{
        color: #424242 !important;
        font-size: 19px !important;
        font-weight: bold !important;
      }
    }
  }
  .v-textarea{
    .v-text-field__slot{
      textarea{
        color: #424242 !important;
        font-size: 19px !important;
        font-weight: normal !important;
      }
    }
  }
  .date{
    .v-text-field__slot{
      .v-label{
        font-size: 14px !important;
        color: #666666 !important;
      }
      input{
        color: #424242 !important;
        font-size: 17px !important;
      }
    }
  }
  .mdi-calendar-month{
    color: #757575 !important;
  }
  .v-btn:not(.v-btn--round).v-size--large{
    height: 36px !important;
  }
  .v-input--is-dirty{
    .v-file-input__text{
      color: #606060 !important;
      font-size: 15px !important;
      font-weight: 500;
    }
  }
}
</style>