var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{on:{"click:outside":_vm.closeDialog},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form"},[_c('h1',[_vm._v("新規お知らせ作成")]),_c('div',[_c('div',{staticClass:"mt-5"},[_c('h4',[_vm._v(_vm._s(_vm.$t('webPageManage.noticeDetail.title')))]),_c('v-text-field',{staticClass:"mt-1 text-newnotice",staticStyle:{"width":"1000px"},attrs:{"outlined":"","rules":[_vm.$rules.required, _vm.$rules.checkLenghInput(255)]},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('div',[_c('h4',[_vm._v(_vm._s(_vm.$t('webPageManage.noticeDetail.text')))]),_c('v-textarea',{staticClass:"mt-1",attrs:{"auto-grow":"","outlined":"","rows":"15","rules":[_vm.$rules.required, _vm.$rules.checkTextAreaLength()],"row-height":"15"},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})],1),_c('div',[_c('v-file-input',{staticStyle:{"width":"700px"},attrs:{"label":_vm.srcImgPre ? _vm.srcImgPre : '',"filled":"","prepend-icon":"mdi-camera","messages":[_vm.$messages.fileLimit({type: '画像 (JPG、GIF、PND、TIFF、PSD等)'})]},on:{"change":_vm.showImagePreview}})],1),_c('v-card',{staticClass:"pl-3",attrs:{"height":"150px","width":"250px","outlined":"","label":_vm.srcImgPre ? _vm.srcImgPre : '',"img":_vm.srcImgPre}},[_c('p',{staticClass:"px-16 py-16",class:{ displayNone: _vm.display }},[_vm._v(" "+_vm._s(_vm.$t('webPageManage.noticeDetail.ImagePreview'))+" ")])]),_c('div',{staticClass:"d-flex align-center mt-5 mb-10"},[_c('DatePickerSelectFromTo',{attrs:{"dates":_vm.listDate,"dateFrom":_vm.listDate[0].date,"dateTo":_vm.listDate[1].date,"attrTagInput":{
              dense: true,
              'hide-details': true,
              label:'掲載日',
              className: 'color-calender',
              type: 'text-field',
              hasBtnResetDatePicker: false,
              outlined: true
            },"icon":true,"rules":[_vm.$rules.required],"showTilde":""},on:{"change":_vm.updateListDate,"reset":_vm.updateListDate}})],1)],1),_c('div',[_c('div',{staticClass:"d-flex justify-end"},[_c('div',[_c('v-btn',{staticClass:"t-btn--prm mx-2",attrs:{"large":""},on:{"click":function($event){return _vm.save(0)}}},[_vm._v(" "+_vm._s(_vm.$t('webPageManage.noticeInformationEditing.SaveAsDraft'))+" ")]),_c('v-btn',{staticClass:"t-btn--prm mx-2",attrs:{"large":""},on:{"click":function($event){return _vm.save(1)}}},[_vm._v(" "+_vm._s(_vm.$t( 'webPageManage.noticeInformationEditing.PostedAsImportantNotice' ))+" ")]),_c('v-btn',{staticClass:"t-btn--prm mx-2",attrs:{"large":""},on:{"click":function($event){return _vm.save(2)}}},[_vm._v(" "+_vm._s(_vm.$t('webPageManage.noticeInformationEditing.Post'))+" ")])],1)])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }