<template>
  <div>
    <v-dialog @click:outside="closeDialog" v-model="dialog">
      <div>
        <!-- title -->
        <h1 v-if="page === 1">
          {{ $t('webPageManage.noticeInformationEditing.DraftEditing') }}
        </h1>
        <h1 v-if="page === 2">
          {{ $t('webPageManage.noticeInformationEditing.InformationEdit') }}
        </h1>
        <h1 v-if="page === 3">
          {{ $t('webPageManage.noticeInformationEditing.InformationEdit') }}({{
            $t('webPageManage.noticeInformationEditing.NotPosted')
          }})
        </h1>
        <!-- end title -->

        <!-- form -->
        <v-form ref="form">
          <div>
            <!-- Content -->
            <div class="mt-5">
              <span>{{ $t('webPageManage.noticeDetail.title') }}</span>
              <v-text-field
                outlined
                style="width: 1000px"
                class="mt-1"
                v-model="title"
                :rules="rules.required"
              ></v-text-field>
            </div>
            <div>
              <span>{{ $t('webPageManage.noticeDetail.text') }}</span>
              <v-textarea
                auto-grow
                outlined
                rows="15"
                :rules="[$rules.required, $rules.checkTextAreaLength()]"
                row-height="15"
                class="mt-1"
                v-model="body"
              ></v-textarea>
            </div>
            <!-- End content -->

            <!-- Display img -->
            <div>
              <v-file-input
                style="width: 700px"
                :label="srcImgPre ? srcImgPre : ''"
                filled
                @change="showImagePreview"
                prepend-icon="mdi-camera"
                :messages="[$messages.fileLimit({size: '10MB', type: '画像 (JPG、GIF、PND、TIFF、PSD等)'})]"
              ></v-file-input>
            </div>
            <v-card
              class="pl-3"
              height="150px"
              width="250px"
              outlined
              :img="srcImgPre"
            >
              <p class="px-16 py-16" :class="{ displayNone: display }">
                {{ $t('webPageManage.noticeDetail.ImagePreview') }}
              </p>
            </v-card>
            <!-- End display img -->

            <!-- Date picker -->
            <div class="d-flex align-center mt-5 mb-10">
              <DatePickerSelectFromTo 
                :dates="listDate"
                :dateFrom="fromDate"
                :dateTo="toDate"
                @change="updateListDate"
                :attrTagInput="{
                  dense: true,
                  'hide-details': true,
                  className: 'color-calender',
                  type: 'text-field',
                  hasBtnResetDatePicker: false,
                  outlined: true,
                }"
                :icon="true"
                showTilde
                @reset="updateListDate"
              ></DatePickerSelectFromTo>
            </div>
            <!-- End date picker -->
          </div>
        </v-form>
        <!--End form-->

        <!-- button -->
        <!-- case page = 1 -->
        <div v-if="page === 1">
          <div class="d-flex justify-space-between">
            <div class="mt-3 textGray">
              <h2>
                {{ $t('webPageManage.noticeInformationEditing.CreationDate') }}
                : {{ data.createdAt | formatDate }}a
              </h2>
            </div>
            <div>
              <v-btn
                large
                class="t-btn--red-dark mx-2"
                @click="setFunc(0, data.id)"
              >
                {{ $t('webPageManage.noticeInformationEditing.delete') }}
              </v-btn>
              <v-btn large class="t-btn--prm mx-2" @click="save(0)">
                {{ $t('webPageManage.noticeInformationEditing.SaveAsDraft') }}
              </v-btn>
              <v-btn large class="t-btn--prm mx-2" @click="save(1)">
                {{
                  $t(
                    'webPageManage.noticeInformationEditing.PostedAsImportantNotice',
                  )
                }}
              </v-btn>
              <v-btn large class="t-btn--prm mx-2" @click="save(2)">
                {{ $t('webPageManage.noticeInformationEditing.Post') }}
              </v-btn>
            </div>
          </div>
        </div>

        <!-- case page = 2 -->
        <div v-if="page === 2">
          <div class="d-flex justify-space-between">
            <div class="mt-3 textGray">
              <h2>
                {{ $t('webPageManage.noticeInformationEditing.CreationDate') }}
                : {{ data.createdAt | formatDate }}
              </h2>
            </div>
            <div>
              <v-btn
                large
                class="t-btn--red-dark mx-2"
                @click="setFunc(1, data.id)"
              >
                {{ $t('webPageManage.noticeInformationEditing.Hide') }}
              </v-btn>
              <v-btn large class="t-btn--prm mx-2" @click="save(2)">
                {{ $t('webPageManage.noticeInformationEditing.keep') }}
              </v-btn>
            </div>
          </div>
        </div>

        <!-- case page = 3 -->
        <div v-if="page === 3">
          <div class="d-flex justify-space-between">
            <div class="mt-3 textGray">
              <h2>
                {{ $t('webPageManage.noticeInformationEditing.CreationDate') }}
                : {{ data.createdAt | formatDate }}
              </h2>
            </div>
            <div>
              <v-btn large class="t-btn--prm mx-2" @click="save(3)">
                {{ $t('webPageManage.noticeInformationEditing.keep') }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapMutations } from 'vuex';
import gql from 'graphql-tag';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions'
import axios from 'axios'
import DatePickerSelectFromTo from '@/components/Input/datePicker/DatePickerSelectFromTo.vue';

const newData = () => ({
  idx: 0,
  // Dialog alret
  titleDialogAlert: '',
  funcDialogAlert: null,
  dialogAlert: false,

  // data content
  title: null,
  body: null,
  fromDate: null,
  toDate: null,
  srcImgPre: null,
  display: false,
  uploadUrl:'',
  fileUrl:'',
  menuDate: [],
  listDate: [
    { 
      id: 1,
      date: new Date().toISOString().substr(0, 10),
      attrs: {
        label: '掲載開始'
      }
    },
    {
      id: 2,
      date: new Date().toISOString().substr(0, 10),
      attrs: {
        label: '掲載終了'
      }
    },
  ],
})

export default {
  name: 'noticeInformationEditing',
  data() {
    return {
      ...newData()
    };
  },
   components : {
    DatePickerSelectFromTo
  },
  props: {
    visible: Boolean,
    page: Number,
    data: Object,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    rules() {
      return {
        required: [(value) => !!value || this.$t('rules.nameIsRequired')],
      }
    },
  },

  mounted () {
     if (this.data.imageUrl !== null) {
        this.display = true
      } else this.display = false
  },

  watch: {
    data () {
      // reset the state
      const data = newData();
      Object.keys(data).forEach(key => {
        this[key] = data[key]
      })
      this.title = this.data.title
      this.body = this.data.body
      this.fromDate = this.data.showFromDate
      this.toDate = this.data.showToDate
      if (this.data.imageUrl !== null) {
        this.display = true
      } else this.display = false
      this.srcImgPre = this.data.imageUrl
    }
  },

  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions([
      'updateWebNotification',
      'createWebNotification',
      'deleteWebNotification',
      'hiddenWebNotification',
      'fetchWebNotificationList',
    ]),
    ...mapMutations([
      'setCopyErrorText',
      'setTitleNotice',
      'setImportantNotice',
      'setPublishedNotice',
      'setAlertSuccess',
      'setTitleNotice',
      'setBodyNotice',
      'setImageUrlNotice',
      'setShowFromDateNotice',
      'setShowToDateNotice',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser'
    ]),

    showImagePreview(event) {
      if (event != null) {
        const theReader = new FileReader();
        theReader.onloadend = async () => {
          this.srcImgPre = await theReader.result;
        };
        theReader.readAsDataURL(event);
        this.img = event.name
        this.display = true;
        this.getFile(event)
      } else {
        this.srcImgPre = null;
        this.display = false;
      }
    },

    getFile(e){
      if (e) {
        this.file = e
        this.getInfoFile(e.type, e.size, e.name)
      }
    },

    getInfoFile(type, length, name) {
      const query = `
            mutation (
              $contentType: String!
              $contentLength: Int!
              $type: String! ,
              $fileName: String
            ) {
              generateUploadUrl(
                type: $type
                contentType: $contentType
                contentLength: $contentLength,
                fileName: $fileName
              ) {
                uploadUrl
                fileUrl 
              }
            }
          `
      const variables = {
            type: 'webNotification/images',
            contentType: type,
            contentLength: length,
            fileName: name
          }
      this.$apollo
        .mutate({
          mutation: gql`${query}`,
          variables: variables,
        })
        .then((data) => {
          this.uploadUrl = data.data.generateUploadUrl.uploadUrl;
          this.fileUrl = data.data.generateUploadUrl.fileUrl;
          axios.put(
          this.uploadUrl,
           this.file,
          {
            headers: {
              'Content-Type': this.file.type,
            },
          },
          )
        })
        .catch(async (error) => {
          this.setCopyErrorText(this.getCopyErrorTextView(query, variables, error.graphQLErrors))
          const errorTmp = await handlErrorView(error.graphQLErrors, this.setPermissionUser, this.setRoleAdminUser)
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true })
          }
      });
    },

    save(x) {
      if (this.$refs.form.validate()) {
        this.setTitleNotice(this.title);
        this.setBodyNotice(this.body);
        if(this.fileUrl){
          this.setImageUrlNotice(this.fileUrl);
        }else{
          this.setImageUrlNotice(this.data.imageUrl);
        }
        this.setShowFromDateNotice(this.fromDate)
        this.setShowToDateNotice(this.toDate)

        if(this.page === 1){
          switch (x) {
            case 0:
              this.setImportantNotice(false);
              this.setPublishedNotice(false);
              break;
            case 1:
              this.setImportantNotice(true);
              this.setPublishedNotice(true);
              break;
            default:
              this.setImportantNotice(false);
              this.setPublishedNotice(true);
          }
        }else{
          switch (this.page) {
            case 2:
              this.setImportantNotice(this.data.important);
              this.setPublishedNotice(true);
              break;
            default:
              this.setImportantNotice(this.data.important);
              this.setPublishedNotice(true);
          }
        }
        this.updateWebNotification();
        this.closeDialog();
      }
    },

    setFunc(x, id) {
      if (x === 0) {
        this.deleteWebNotification(id);
      } else {
        this.hiddenWebNotification(id);
      }
      this.closeDialog()
    },

    closeDialog() {
      this.idx = this.idx + 1;
      this.$emit('close');
    },

    updateListDate(item){
      if (item.id === 1) {
        this.fromDate = item.date
      } else {
        this.toDate = item.date
      }
    }
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.boder_padding {
  padding: 10px 0 10px 20px;
}
.boder_gray_padding {
  border-bottom: 1px solid gray;
  padding: 10px 0 10px 20px;
}
.textGray {
  color: gray;
}

.displayNone {
  display: none;
}

::v-deep {
  .v-dialog {
    width: 1300px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
</style>
